import { StaticIconWrapper, Tooltip } from "@kablamooss/geo-ds-core-components";
import type {
  AppRunQaStatusProperty,
  AppRunScenario,
  AppRunStatus,
} from "../../../../.rest-hooks/types";
import {
  getProjectRunAcceptedLabel,
  getProjectRunHasAcceptanceAction,
} from "../../../../config/projects";
import { Accepted, AcceptedDisabled, NotAccepted } from "../../../../icons";
import type { ProjectType } from "../../../../types";

interface AcceptanceStatusProps {
  accepted: boolean;
  projectType: ProjectType;
  qaStatus: AppRunQaStatusProperty | undefined;
  scenario: AppRunScenario;
  status: AppRunStatus;
}

const AcceptanceStatus = ({
  accepted,
  projectType,
  qaStatus,
  scenario,
  status,
}: AcceptanceStatusProps) => {
  const runAcceptedLabel = getProjectRunAcceptedLabel(projectType);

  if (
    !getProjectRunHasAcceptanceAction({
      projectType,
      scenario,
      status,
      qaStatus,
    })
  ) {
    return (
      <StaticIconWrapper icon={AcceptedDisabled} size="sm" variant="disabled" />
    );
  }

  if (accepted) {
    return (
      <Tooltip message={runAcceptedLabel}>
        <StaticIconWrapper icon={Accepted} size="sm" variant="primary" />
      </Tooltip>
    );
  }

  return <StaticIconWrapper icon={NotAccepted} size="sm" variant="disabled" />;
};

export default AcceptanceStatus;
